import React, { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { menuExpansionStyles as classes } from './styleObjects/MenuExpansion';
import { keyIsEscape } from '@helpers/keyPressEventHelper';
import { DropDownMenu } from './DropDownMenu';
import { GridContainer } from '../GeotabGridComponents';
import { NavbarDropDownContent } from '@models/navigationBar';
import { Text } from '@web-for-marketing/react-ui';
import { ClassNames } from '@emotion/react';

interface MenuExpansionProps {
    name: string;
    key?: string | number;
    selectedItem: string;
    setSelectedItem: (item: string) => void;
    dropDownContent: NavbarDropDownContent;
}

export function MenuExpansion({
    name,
    key,
    dropDownContent,
    selectedItem,
    setSelectedItem,
}: MenuExpansionProps): JSX.Element {
    const expanded = name === selectedItem;
    const handleEscapeButtonPress = (event: KeyboardEvent): void => {
        keyIsEscape(event) && setSelectedItem('');
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeButtonPress);
        return () => {
            document.removeEventListener('keydown', handleEscapeButtonPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function toggleExpanded(): void {
        if (selectedItem !== name) {
            setSelectedItem(name);
        } else {
            setSelectedItem('');
        }
    }

    return (
        <ClassNames>
            {({ css }) => (
                <Accordion
                    expanded={expanded}
                    classes={{
                        root: `${css(classes.expansionPanel)} ${
                            expanded ? css(classes.animateExpansionMenu) : ''
                        } noJs-menu`,
                    }}
                    onChange={toggleExpanded}
                    key={key}
                >
                    <AccordionSummary
                        tabIndex={0}
                        css={[classes.menuTitles, expanded && classes.showUnderline]}
                        classes={{
                            focusVisible: css(classes.expansionTitleFocus),
                            content: css(classes.noSpacing),
                        }}
                    >
                        <Text variant='body2' color='tertiary'>
                            {name}
                        </Text>
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{
                            root: css(classes.menuContent),
                        }}
                    >
                        <span css={classes.topShadow}></span>
                        <GridContainer css={classes.gridBorder} component='nav'>
                            <DropDownMenu
                                columnData={{
                                    hasDivider: dropDownContent.hasDivider,
                                    ...dropDownContent.leftColumn,
                                }}
                                closeMenu={() => {
                                    setSelectedItem('');
                                }}
                            />
                            <DropDownMenu
                                columnData={{
                                    hasDivider: dropDownContent.hasDivider,
                                    ...dropDownContent.rightColumn,
                                }}
                                closeMenu={() => {
                                    setSelectedItem('');
                                }}
                            />
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            )}
        </ClassNames>
    );
}
