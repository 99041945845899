import React from 'react';
import { breakpoints } from '@web-for-marketing/react-ui';

const separator = {
    display: 'block',
    width: '6rem',
    height: '2rem',
    position: 'relative',
    userSelect: 'none',
    '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'block',
        transform: 'translateX(-50%)',
        userSelect: 'none',
        borderRight: '1px solid #CFD5DC',
    },
    [`@media (max-width: ${breakpoints.lg}px)`]: {
        width: '3rem',
    },
} as const;

export function VerticalSeparator(props: { className?: string }): JSX.Element {
    return <span css={separator} {...props}></span>;
}
