import React from 'react';
import { useAtom } from 'jotai';
import { navbarAtom } from '@atoms/navigation';
import { Navbar } from '@components/NavbarV2/Navbar';
import { NavbarV1 } from './NavbarV1';

export default function NavigationBarComponent(): JSX.Element {
    const [navbar] = useAtom(navbarAtom);

    return navbar?.version === 2 ? <Navbar navbar={navbar} /> : <NavbarV1 />;
}
