import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Divider, ClickAwayListener } from '@mui/material';
import { navigationBarStyles as classes } from './styleObjects/NavigationBar';
import { TopMicroMenu } from './NavPanelComponents/TopMicroMenu';
import { MainMenu } from './NavPanelComponents/MainMenu';
import { useAtom } from 'jotai';
import { isAuthenticatedAtom } from '@atoms/authentication';
import { navHeightAtom, remSizeAtom } from '@atoms/appSettings';

export function NavbarV1(): JSX.Element {
    const [remSize] = useAtom(remSizeAtom);
    const [navHeight, setNavHeight] = useAtom(navHeightAtom);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const menuNav = useRef<HTMLDivElement>(null);
    const getNavHeight = (): number | null => menuNav && menuNav.current && menuNav.current.clientHeight;
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);

    useEffect(() => {
        const currentNavHeight = getNavHeight();

        if (currentNavHeight && currentNavHeight != navHeight) {
            setNavHeight(currentNavHeight);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuNav.current]);

    return (
        <div ref={menuNav} css={classes.stickyNavbar} style={remSize >= 14 ? { position: 'relative' } : undefined}>
            <ClickAwayListener
                onClickAway={() => {
                    setSelectedMenuItem('');
                }}
            >
                <AppBar css={classes.header} id='NavigationBar' aria-label='Main navigation'>
                    <TopMicroMenu formPopoutOpen={false} userIsAuthenticated={isAuthenticated} />
                    <MainMenu
                        userIsAuthenticated={isAuthenticated}
                        selectedItem={selectedMenuItem}
                        setSelectedItem={setSelectedMenuItem}
                    />
                </AppBar>
            </ClickAwayListener>
            <Divider css={{ opacity: 0.6 }} />
        </div>
    );
}
